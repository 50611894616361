import baseApiGesttor from "../api"
import BASEAPIFORM from "../api/formData"
import { loginPayload, cadastrarCandidatoPayload, recuperarSenhaPayload, redefinirSenhaPayload, atualizarCurriculoPayload, atualizarAvatarCandidato, formacaoAcademicaPayload, adicionarFormacaoPayload, adicionarCursoPayload, atualizarCursoPayload, adicionarExperienciaPayload, atualizarExperienciaPayload, adicionarAreaInteressePayload, adicionarInteresseRegimePayload } from "../../interfaces/bancoTalentos"

export function auth(payload: loginPayload) {
    return baseApiGesttor.post('/bancoTalentos/login', payload)
}

export function cadastrarCandidato(payload: cadastrarCandidatoPayload) {
    return baseApiGesttor.post('/bancoTalentos', payload)
}

export function recuperarSenha(payload: recuperarSenhaPayload) {
    return baseApiGesttor.post('/bancoTalentos/recuperarSenha', payload)
}

export function novaSenha(payload: redefinirSenhaPayload) {
    return baseApiGesttor.post('/bancoTalentos/redefinirSenha', payload)
}

export function dadosCandidato() {
    return baseApiGesttor.get(`/dadosCandidato`)
}

export function atualizarCurriculo(payload: atualizarCurriculoPayload) {
    return baseApiGesttor.patch(`/atualizarCurriculo`, payload)
}

export function atualizarFotoPerfil(payload:atualizarAvatarCandidato) {
    return BASEAPIFORM.patch(`/atualizarFotoPerfil`, payload)
}

export function atualizarFormacao(payload: formacaoAcademicaPayload, idFormacao:string) {
    return baseApiGesttor.patch(`/atualizarFormacao/${idFormacao}`, payload)
}

export function adicionarFormacao(payload: adicionarFormacaoPayload) {
    return baseApiGesttor.post(`/formacaoAcademica`, payload)
}

export function deletarFormacao(idFormacao: string) {
    return baseApiGesttor.delete(`/excluirFormacao/${idFormacao}`)
}

export function adicionarCurso(payload: adicionarCursoPayload) {
    return baseApiGesttor.post(`/cursoComplementar`, payload)
}

export function atualizarCurso(payload: atualizarCursoPayload, idCurso: string) {
    return baseApiGesttor.patch(`/atualizarCurso/${idCurso}`, payload)
}

export function excluirCurso(idCurso: string) {
    return baseApiGesttor.delete(`/excluirCurso/${idCurso}`)
}

export function adicionarExperiencia(payload: adicionarExperienciaPayload) {
    return baseApiGesttor.post(`/experienciaProfissional`, payload)
}

export function atualizarExperiencia(payload: atualizarExperienciaPayload, idExperiencia: string) {
    return baseApiGesttor.patch(`/atualizarExperiencia/${idExperiencia}`, payload)
}

export function excluirExperiencia(idCurso: number) {
    return baseApiGesttor.delete(`/excluirExperiencia/${idCurso}`)
}

export function adicionarAreaInteresse(payload: adicionarAreaInteressePayload) {
    return baseApiGesttor.post(`/areaInteresse`, payload)
}

export function excluirInteresseArea(idArea: number) {
    return baseApiGesttor.delete(`/excluirAreaInteresse/${idArea}`)
}

export function adicionarRegimeInteresse(payload: adicionarInteresseRegimePayload) {
    return baseApiGesttor.post(`/interesseRegime`, payload)
}

export function excluirRegimeInteresse(idArea: number) {
    return baseApiGesttor.delete(`/excluirRegimeInteresse/${idArea}`)
}